import React from 'react';

import { PaymentToken, Subtotal } from '#mrktbox/types';

import SquareForm from '#components/creditCards/SquareForm';

export type Status = 'success'
  | 'error'
  | 'noIntegration'
  | 'badCardNumber'
  | 'badCardExpiry'
  | 'badCardCVV'
  | 'badCardPostal'
  | 'cardDeclined'
  | 'insufficientFunds';

interface CreditCardFormProps {
  total? : Subtotal,
  onSaved? : (token : PaymentToken) => Status | Promise<Status>,
  onCancel? : () => void,
}

function CreditCardForm(props : CreditCardFormProps) {

  return (
    <SquareForm {...props} />
  );
}

export default CreditCardForm;
